import { WorkflowStatus } from './../../../../constants/workflow-status.constant';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import {
  faAngleDown,
  faAngleUp,
  faCalendarAlt,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  DepartmentResponse,
  DepartmentControllerService,
  ComponentControllerService,
  ComponentResponse,
  MasterDataControllerService,
  ObjectiveTypeResponse,
  ErReasonControllerService,
  ErReasonResponse,
  JiraControllerService,
  TaskControllerService,
  EmployeeResponse,
  EnumResponse,
  MainTaskResponse,
  DropdownTask,
} from "@set-it-workflow/set-it-workflow-ts-angular";
import { ServiceRequestTypeResponse } from "@set-it-workflow/set-it-workflow-ts-angular/model/serviceRequestTypeResponse";
import { Subscription } from "rxjs";
import { environment } from 'src/environments/environment';
import { TaskCategoryEnum } from '../../constant/task-category-enum';
import { convertStringToWorkflowStatus } from '../../function/state.function';
import { DropdownTaskSR } from '@set-it-workflow/set-it-workflow-ts-angular/model/dropdownTaskSR';
import { UserProfile } from 'src/app/service/user-profile.service';
import { roleUser } from 'src/app/constants/role.constant';

@Component({
  selector: "app-request-detail-box",
  templateUrl: "./request-detail-box.component.html",
  styleUrls: ["./request-detail-box.component.scss"],
})
export class RequestDetailBoxComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() fieldsData: any;
  @Input() isReadonly: boolean;
  @Input() isCollapse: boolean;
  @Input() userProfile: UserProfile;
  @Output() isCollapseChange = new EventEmitter<boolean>();
  @Input() taskId: number;
  @Input() status: string;
  @Output() formChange = new EventEmitter<FormGroup>();
  @Output() serviceListChange = new EventEmitter<ComponentResponse[]>();
  @Input() isFormRelease: boolean = false;
  @Input() taskCategoryId: number;
  @Input() listAllEmployee: EmployeeResponse[] = [];
  @Input() mainTaskValue: MainTaskResponse;
  @Input() isCheckSSD: boolean = false;
  @Input() objTypeList: ObjectiveTypeResponse[] = [];
  @Output() isIssueChangId = new EventEmitter<boolean>();
  @Output() isIssueIncidentChangeId = new EventEmitter<boolean>();

  private subscription: Subscription;
  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public faCalendarAlt = faCalendarAlt;
  public faCheckCircle = faCheckCircle;
  public faTimesCircle = faTimesCircle;

  public deptList: DepartmentResponse[] = [];
  public srTaskList: DropdownTaskSR[] = [];
  public supportdeptList: DepartmentResponse[] = [];
  public dataOwnerDeptList: DepartmentResponse[] = [];
  public serviceList: ComponentResponse[] = [];
  public serviceRequestTypeList: ServiceRequestTypeResponse[] = [];
  public reasonList: ErReasonResponse[];
  public isValidIssue: boolean;
  public isValidSRTask: string;
  public isValidIssueIncident: boolean;
  public issueName = null;
  public issueNameIncident = null;
  public reasonId: number;
  public placeholderIncidentChangeId: string;

  serviceRequestTypeForGetDataProd: number = 4;
  isServiceRequestTypeForGetDataProd: boolean = false;
  
  //public isShowRequireQuotaion = false;
  isTaskER = false;
  time = { hour: new Date().getHours, minute: new Date().getMinutes };
  meridian = true;
  filteredGroups: any[];
  private readonly IT_SOLUTION_SYS_DEPT_ID = '100270';

  constructor(
    private departmentService: DepartmentControllerService,
    private componentService: ComponentControllerService,
    private masterDataService: MasterDataControllerService,
    private erReasonService: ErReasonControllerService,
    private taskService: TaskControllerService
  ) { }

  ngOnInit() {
    this.subscription = new Subscription();
    this.departmentService.getITDepartmentUsingGET().subscribe((result) => {
      this.deptList = result.data;
    });

    this.masterDataService.getObjectiveTypesUsingGET().subscribe((result) => {
      this.objTypeList = result.data;
    });

    this.departmentService.getAllDepartmentUsingGET().subscribe((result) => {
      this.supportdeptList = result.data;
    });

    if (this.userProfile.role === roleUser.user) {
      this.departmentService.getDepartmentIgnoreITUsingGET().subscribe((result) => {
        this.dataOwnerDeptList = result.data;
      });
    } else {
      this.departmentService.getDepartmentByTreeLevelUsingGET(5).subscribe((result) => {
        this.dataOwnerDeptList = result.data;
      });
    }

    this.masterDataService.getServiceRequestTypesUsingGET().subscribe((result) => {
      this.serviceRequestTypeList = result.data;
    });
    
    this.initValue();
    this.checkTaskER();
    this.getReasonList();
    this.onDepartmentChange();
    this.onFormChange();
    this.validateJiraIssue();
    this.validateJiraIssueIncidentId();
    this.setIncidentChangePlaceholder();
    this.onSelectReasonChange();
    this.onServiceRequestTypeChange();
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  private initValue() {
    if (this.form.controls.itDepartment.value) {
      this.getComponent(this.form.controls.itDepartment.value);
      this.getSRList(this.form.controls.itDepartment.value)
    }
    this.reasonId = this.form.controls.reason.value;

    if (this.form.controls.serviceRequestType && this.form.controls.serviceRequestType.value === this.serviceRequestTypeForGetDataProd) {
      this.isServiceRequestTypeForGetDataProd = true;
      this.setRequiredFieldDataOwner(true);
      this.setRequiredFieldEndOfUsage(true);
    }
  }

  getSRList(itdept: string) {
    if (this.taskCategoryId && this.taskCategoryId == TaskCategoryEnum.ER.id) {
      this.taskService.getSRTaskListUsingGET(itdept).subscribe(result => {
        this.srTaskList = [...result.data];
      })
    }

  }
  onSearchSrTask(keyword: string, listSrTask: DropdownTaskSR) {
    keyword = keyword.toLocaleLowerCase().replace(/^0+(?=\d)/, '');
    const taskId = listSrTask.taskId ?
      listSrTask.taskId.toString().indexOf(keyword) > -1 : false;
    const taskName = listSrTask.taskName ?
      listSrTask.taskName.toLocaleLowerCase().indexOf(keyword) > -1 : false;
    const childTask = listSrTask.srChildTask ? listSrTask.srChildTask.some(x=>x.toString() == keyword) : false;
    return taskId || taskName || childTask;
  }

  private checkTaskER(): void {
    if (this.taskCategoryId && this.taskCategoryId == TaskCategoryEnum.ER.id) {
      this.isTaskER = true;
    } else {
      this.isTaskER = false;
    }
  }

  private onFormChange() {
    this.subscription.add(
      this.form.valueChanges.subscribe(() => {
        this.formChange.emit(this.form);

      })

    );
    this.subscription.add(
      this.form.controls.changeId.valueChanges.subscribe((x) => {
        this.formChange.emit(this.form);
        if (this.isTaskER && x && this.isCheckSSD) {
          this.validateJiraIssue(x);
        } else {
          this.isValidIssue = false;
          this.issueName = null;
          this.isIssueIncidentChangeId.emit(this.isValidIssueIncident);
        }
      })
    );
    this.subscription.add(
      this.form.controls.incidentId.valueChanges.subscribe((x) => {
        this.formChange.emit(this.form);
        if (this.isTaskER && x && this.isCheckSSD) {
          this.validateJiraIssueIncidentId(x);
        } else {
          this.isValidIssueIncident = false;
          this.issueNameIncident = null;
          this.isIssueIncidentChangeId.emit(this.isValidIssueIncident);
        }
      })
    );
  }

  onChangeIncident(x) {

    if (this.convertStringToWorkflowStatus(this.status) === EnumResponse.WorkflowStatusEnum.DEVCREATEERTASK) {
      if (this.isTaskER && x) {
        this.validateJiraIssueIncidentId(x);
      } else {
        this.isValidIssueIncident = false;
        this.issueNameIncident = null;
        this.isIssueIncidentChangeId.emit(this.isValidIssueIncident);
      }
    }
  }

  private onDepartmentChange() {
    this.subscription.add(
      this.form.controls.itDepartment.valueChanges.subscribe(
        (value: string) => {
          if (value) {
            this.getComponent(value);
            this.getSRList(value);
          }
          if (!this.isFormRelease) {
            this.form.controls.serviceName.setValue(null);
            this.form.controls.serviceName.updateValueAndValidity();
            if (this.convertStringToWorkflowStatus(this.status) === EnumResponse.WorkflowStatusEnum.CSTASK) {
              this.form.controls.itHead.setValue(null);
              this.form.controls.itHead.updateValueAndValidity();
            }
          }

        }
      )
    );
  }

  private getReasonList() {
    this.erReasonService.getErReasonsUsingGET().subscribe((result) => {
      this.reasonList = result.data;
    });
  }

  private onSelectReasonChange() {
    this.form.controls.reason.valueChanges.subscribe((x) => {
      this.form.controls.changeId.reset();
      this.form.controls.incidentId.reset();
      if (x === 3) {
        this.form.controls.autoCreateIncidentId.setValue(true);
      }
    });
  }

  private setIncidentChangePlaceholder() {
    if (environment.production) {
      this.placeholderIncidentChangeId = 'SSD-xxxxxx';
    } else {
      this.placeholderIncidentChangeId = 'SSDDEV-xxxxxx';
    }
  }

  onReasonChange(reasonId: number) {
    this.reasonId = reasonId;
    this.isValidIssue = false;
    this.isValidIssueIncident = false;
  }

  validateJiraIssue(x?: string) {
    if (x) {
      this.onValidateJiraIssue(x, 'CHANGE');
    } else if (this.form.value.changeId) {
      this.onValidateJiraIssue(this.form.value.changeId, 'CHANGE');
    }
  }
  validateJiraIssueIncidentId(x?: string) {
    if (x) {
      this.onValidateJiraIssue(x, 'INCIDENT');
    } else if (this.form.value.incidentId) {
      this.onValidateJiraIssue(this.form.value.incidentId, 'INCIDENT');
    }
  }

  public onValidateJiraIssue(value: string, mode: 'CHANGE' | 'INCIDENT') {
    if (value) {
      this.taskService.isExistingIssueUsingGET(mode, value).subscribe((res) => {
        if (mode === 'CHANGE') {
          this.isValidIssue = res.data.existIssue;
          this.issueName = res.data.issueName;
          this.isIssueChangId.emit(this.isValidIssue);
        } else {
          this.isValidIssueIncident = res.data.existIssue;
          this.issueNameIncident = res.data.issueName;
          this.isIssueIncidentChangeId.emit(this.isValidIssueIncident);
        }

      });
    }
  }

  private getComponent(deptId: string): void {
    this.componentService
      .getComponentsByDeptIdUsingGET(deptId)
      .subscribe((result) => {
        this.serviceList = result.data;
        this.serviceListChange.emit(this.serviceList);
      });
  }

  disableDatePicker(controlName): boolean {
    if (this.form && this.form.get(controlName).disabled) {
      return true;
    } else return false;
  }
  toggleChange() {
    this.isCollapseChange.emit(this.isCollapse);
  }
  searchEmployee(event) {
    let query = event.query;
    let filteredGroups = [];
    for (let optgroup of this.listAllEmployee) {
      if (optgroup.nameEn.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filteredGroups.push({
          toSupportBusinessId: null,
          nameEn: optgroup.nameEn,
          empId: optgroup.empID,
        });
      }
    }
    this.filteredGroups = filteredGroups;
  }

  get isShowRequireQuotaion() {
    if (this.form.controls.itDepartment.value === this.IT_SOLUTION_SYS_DEPT_ID) {
      return true;
    } else {
      return false;
    }
  }

  public convertStringToWorkflowStatus(
    state: string
  ): EnumResponse.WorkflowStatusEnum {
    return convertStringToWorkflowStatus(state);
  }

  private onServiceRequestTypeChange() {
    if (this.form && this.form.controls.serviceRequestType) {
      this.form.controls.serviceRequestType.valueChanges.subscribe((x) => {
        if (x === this.serviceRequestTypeForGetDataProd) {
          this.isServiceRequestTypeForGetDataProd = true;
          this.setRequiredFieldDataOwner(true);
          this.setRequiredFieldEndOfUsage(true);
        } else {
          this.isServiceRequestTypeForGetDataProd = false;
          this.setRequiredFieldDataOwner(false);
          this.setRequiredFieldEndOfUsage(false);
          this.form.controls.dataOwner.reset();
          this.form.controls.endOfUsage.reset();
        }
      });
    }
  }

  private setRequiredFieldDataOwner(required: boolean) {
    if (required) {
      this.fieldsData.dataOwner = {
        req: true,
        editable: true,
      };
      this.form.controls.dataOwner.setValidators(Validators.required);
      this.form.controls.dataOwner.updateValueAndValidity();

    } else {
      this.fieldsData.dataOwner = {
        req: false,
        editable: true,
      };
      this.form.controls.dataOwner.setValidators(null);
      this.form.controls.dataOwner.updateValueAndValidity();
    }
  }
  private setRequiredFieldEndOfUsage(required: boolean) {
    if (required) {
      this.fieldsData.endOfUsage = {
        req: true,
        editable: true,
      };
      this.form.controls.endOfUsage.setValidators(Validators.required);
      this.form.controls.endOfUsage.updateValueAndValidity();

    } else {
      this.fieldsData.endOfUsage = {
        req: false,
        editable: true,
      };
      this.form.controls.endOfUsage.setValidators(null);
      this.form.controls.endOfUsage.updateValueAndValidity();
    }
  }
}
